import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// /* global google */
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./Dashboard";
import Invoice from "./Invoice";
import SalarySlips from "./SalarySlips";
import Clients from "./Clients";
import Items from "./Items";
import Reports from "./views/reports/Reports";
import Settings from "./Settings";
// import ResetPassword from "./components/ResetPassword";
import CreateInvoice from "./components/createInvoice/Create";
import Signup from "./components/Signup";
import CreateSalaryslip from "./components/createSalaryslip/CreateSalaryslip";
import CreateItems from "./components/createItems/CreateItems";
import CreateClients from "./components/createClients/CreateClients";
import UserSettings from "./UserSettings";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setUser } from "./store/slices/userSlice";
// import Preview from "./components/createInvoice/Preview";
import ProtectedRoute from "./auth/ProtectedRoute";
import { useGetUserByAuthTokenQuery, useLazyGetInvoiceTemplatesQuery } from "./store/apis/baseApi";
// import { ThreeCircles } from "react-loader-spinner";
import ForgetPassword from "./views/ForgetPassword";
import Logins from "./views/Logins";
import CustomLoader from "./components/CustomLoader";
import Landing from "./views/Landing";
import Quotations from "./views/Quotations";
import CreateQuotation from "./components/createQuotation/CreateQuotation";
import { setBackgroundUrl } from "./store/slices/backgroundPicture";
import GroupPayslip from "./views/groupPayslip/GroupPayslip";
import CreateGroupPayslip from "./components/createGroupPayslipSalarySlip/CreateGroupPayslip";
import { errorMessage } from "./components/toastMessages/ToastifyMsg";
import PageNotFound from "./components/notFound/PageNotFound";
import { IMG_URL } from "./common/common";
import { deepCopy } from "./utils/utils";
import { setAllTemplates } from "./store/slices/allTemplates";
import EmailHistory from "./views/emailHistory/EmailHistory";
import ReactGA from "react-ga4";
// import { clarity } from 'react-microsoft-clarity';
import { clarity } from 'clarity-js';
import Expenses from './views/expenses/Expenses';
import CreateExpense from './components/createExpense/CreateExpense';
import ContractViewer from './views/contracts/ContractViewer';
import ContractsListing from './views/contracts/ContractsListing';
import Subscribe from './views/scubscription/Subscribe';
import SubscriptionSuccess from './views/scubscription/SubscriptionSuccess';
import SubscriptionFailure from './views/scubscription/SubscriptionFailure';
import {  useGetUserPlanServiceMutation } from './store/apis/laravelApi';
import { setPlanDetails } from './store/slices/subscribedPlanDetails';
import SubscriptionHistory from './UserSettings/SubscriptionHistory';

let globalRedirect = "/";

function App() {
  AOS.init();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  //// ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, }); 
  
  const TRACKIN_ID ="G-FNVXMXVQ2B"
  useEffect(() => {
    ReactGA.initialize(TRACKIN_ID,{debug:false});
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: ""});
  }, []);

  //---------------------
  // clarity.init(CLARITY_ID);
  // Cookie consent
  // clarity.consent();
  // Check if Clarity has been initialized before calling its methods
  // if (clarity.hasStarted()) {
    //   clarity.identify(auth.user.id, { userProperty: 'value' });
    // }
    // ------------------
    // clarity.consent();
    
    // in-use method
    const CLARITY_ID = 'hyn8j8yhhv';
  clarity.start({
    projectId: CLARITY_ID,
    upload: 'https://m.clarity.ms/collect',
    track: true,
    content: true,
  });
  
  // const planDetail = useSelector(state=>state.planDetails?.planDetails?.plan_usage_details);
  // console.log("planDetail::::",planDetail)
  // const invoicePlanDetail = useSelector(state=>state.planDetails?.planDetails?.plan_usage_details?.invoice);
  // const quotationPlanDetail = useSelector(state=>state.planDetails?.planDetails?.plan_usage_details?.quotation_invoice);
  // const consumersPlanDetail = useSelector(state=>state.planDetails?.planDetails?.plan_usage_details?.consumers);
  // const productsPlanDetail = useSelector(state=>state.planDetails?.planDetails?.plan_usage_details?.products);
  
  // const plans = useSelector((state)=>state.planDetails);

  // console.log("plans::",plans)
  

  const [getInvoiceTemplates] = useLazyGetInvoiceTemplatesQuery();
  // const [getSubscriptionUsage] = useGetSubscriptionUsageMutation();

  const [getUserPlanService] = useGetUserPlanServiceMutation();

  const [bgImage,setBgImage] = useState(null);
  const backgroundStore = useSelector((state)=>state.backgroundUrl);
  const { data, error} = useGetUserByAuthTokenQuery();
  const [getuserisLoading, setGetuserisLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setBgImage(backgroundStore.backgroundUrl);
  }, [backgroundStore])

  useEffect(() => {
    if (auth.user !==null) {
      const handleGetSubUsage =async ()=> {
          try {
          const body = {
            user_email:auth.user?.email,
            user_id:auth?.user?.id
          }

          const resp = await getUserPlanService(body);
          if (resp?.data?.status) {
            dispatch(setPlanDetails(resp.data));
          }
          if (!resp?.data?.status) {
              dispatch(setPlanDetails(resp?.data))
          }
          if (resp?.error) {
              console.log(resp?.error)
          }
        } catch (error) {
          console.log("plans->error",error);
        }
      }
      handleGetSubUsage();
    }
  }, [auth,dispatch, getUserPlanService])
  
  useEffect(() => {
    const handleTemplates = async() => {
  
      const {data,error} = await getInvoiceTemplates();
      if (data) {
        if (data.success) {
          const templateData  =deepCopy(data.data)
          dispatch(setAllTemplates(templateData));
        }
      }
      if (error) {
        errorMessage(error?.error ?? error?.data?.error?.message ?? error.data?.message ?? "Server not responding.")
      }
    }
    if (auth.user !==null) {
        handleTemplates();
    }
  
  }, [getInvoiceTemplates,auth,dispatch])


  useEffect(() => {
    try {
      if (data) {
        if (data.success) {
          dispatch(setUser(data.data));
        // if (data.data.company_bg_image) { 
        //   dispatch(setBackgroundUrl(data.data.company_bg_image));
        // }
        // else{
          dispatch(setBackgroundUrl("company_bg_images/backgroundpic.png"));
        // }
        setGetuserisLoading(false);
        }
        if (!data.success) {
          dispatch(setUser(null));
          setGetuserisLoading(false);
          // navigate('/login'); 
        }
      }
  
      if (error) {
        dispatch(setUser(null));
        localStorage.removeItem("tradingformtoken");
        errorMessage(error?.error);
        setGetuserisLoading(false);
        navigate('/login');
      }
      
    } catch (error) {
      
    }

  }, [data, error, dispatch]);


  return getuserisLoading  ? (
    <CustomLoader/> 
  ) :
   (
    // style={{"backgroundImage": `url('${ `${IMG_URL}${bgImage}`}')`}}
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={
            <ProtectedRoute
              isAllowed={!auth.user}
              redirectedPath={globalRedirect}
            >
              <Logins />
            </ProtectedRoute>
          }
        />

        <Route
          path="/resetpassword"
          element={
            <ProtectedRoute
              isAllowed={!auth.user}
              redirectedPath={globalRedirect}
            >
              <ForgetPassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <ProtectedRoute
            isAllowed={!auth.user}
            redirectedPath={globalRedirect}
            >
              <Signup />
            </ProtectedRoute>
          }
        />
          <Route path='/view-contract/:contractId' element={<ContractViewer/>}/>

        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* <Route path="/newlogin" element={<Logins/>}/> */}
        {/* <Route path="/resetpassword" element={<ForgetPassword/>}/> */}

        <Route
          path="/"
          element={ 
            <ProtectedRoute
            // !!data && 
              isAllowed={!!auth.user}
              redirectedPath="/login"
            >
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route index element={<Landing />} />

          {/* Invoices */}
          <Route path="invoice" element={<Invoice />} />
          {/* <Route path="/invoice/create"
              element={ planDetail?.invoice?.usage_count<planDetail?.invoice?.total ? <CreateInvoice /> : <Navigate to="/subscription-plans" />}
          /> */}
          <Route path="/invoice/create" element={<CreateInvoiceWithLimitCheck type="invoice" RenderComponent={CreateInvoice}/>} />
          {/* <Route path="invoice/create" element={<CreateInvoice />} /> */}
          <Route path="invoice/edit/:invoiceId" element={<CreateInvoice updating={true} />}
          />

          {/* Quotations */}
          <Route path="quotation" element={<Quotations/>}/>
          {/* <Route path="quotation/create" element={planDetail?.quotation_invoice?.usage_count<planDetail?.quotation_invoice?.total? <CreateQuotation/>: <Navigate to="/subscription-plans" />}/> */}
          <Route path="quotation/create" element={<CreateInvoiceWithLimitCheck type="quotation_invoice" RenderComponent={CreateQuotation} />}/>
          {/* <CreateInvoiceWithLimitCheck type="invoice" RenderComponent={CreateInvoice}/> */}
          <Route path="quotation/edit/:quotationId" element={<CreateQuotation updating={true} />}/>

          {/* Contracts */}
          <Route path='/contracts' element={<ContractsListing/>}/>

          {/* Payslip */}
          <Route path="salary-slips" element={<SalarySlips />} />
          {/* <Route path="salary-slips/create" element={planDetail?.single_payslip?.usage_count<planDetail?.single_payslip?.total? <CreateSalaryslip />:<Navigate to="/subscription-plans" />} /> */}
          <Route path="salary-slips/create" element={<CreateInvoiceWithLimitCheck type="single_payslip" RenderComponent={CreateSalaryslip }/>} />
          
          {/* <CreateInvoiceWithLimitCheck type="invoice" RenderComponent={CreateInvoice}/> */}
          <Route path="salary-slips/edit/:salaryId" element={<CreateSalaryslip updating={true} />}
          />
            
          {/* Group payslis */}
          <Route path="salarygroup/:salarygroupId" element={<GroupPayslip/>}/>
          {/* <Route path="salarygroup/:salarygroupId/create-payslip" element={ planDetail?.salary_group_payslip?.usage_count<planDetail?.salary_group_payslip?.total?<CreateGroupPayslip/>:<Navigate to="/subscription-plans" />}/> */}
          <Route path="salarygroup/:salarygroupId/create-payslip" element={ <CreateInvoiceWithLimitCheck type="salary_group_payslip" RenderComponent={CreateGroupPayslip}/>}/>
          
          {/* <CreateInvoiceWithLimitCheck type="invoice" RenderComponent={CreateInvoice}/> */}
          <Route path="salarygroup/:salarygroupId/edit-payslip/:gSalaryId" element={<CreateGroupPayslip updating={true}/>} />

          {/* Consumers */}
          <Route path="clients" element={<Clients />} />
          {/* <Route path="clients/create" element={planDetail?.consumers?.usage_count<planDetail?.consumers?.total?<CreateClients />:<Navigate to="/subscription-plans" />} /> */}
          <Route path="clients/create" element={<CreateInvoiceWithLimitCheck type="consumers" RenderComponent={CreateClients} />} />
          {/* <CreateInvoiceWithLimitCheck type="invoice" RenderComponent={CreateInvoice}/> */}
          <Route path="clients/edit/:clientId" element={<CreateClients updating={true} />}
          />

          {/* Products */}
          <Route path="items" element={<Items />} />
          {/* <Route path="items/create" element={planDetail?.products?.usage_count<planDetail?.products?.total?<CreateItems />:<Navigate to="/subscription-plans" />} /> */}
          <Route path="items/create" element={<CreateInvoiceWithLimitCheck type="products" RenderComponent={CreateItems}/>} />
          {/* <CreateInvoiceWithLimitCheck type="invoice" RenderComponent={CreateInvoice}/> */}
          <Route path="items/edit/:itemId" element={<CreateItems updating={true} />}
          />

          {/* Expense */}
          <Route path="expenses" element={<Expenses/>}/>
          <Route path="expenses/create" element={<CreateExpense/>}/>
          <Route path="expenses/edit/:expenseId" element={<CreateExpense updating={true}/>} />



          {/* Email History */}
          <Route path="email-history" element={<EmailHistory />} />


          <Route path="reports" element={<Reports/>}/>
          <Route path="settings" element={<Settings />} />
          <Route path="usersettings" element={<UserSettings />} />

          <Route path='subscription-plans' element={<Subscribe/>} />
          <Route path='subscription-success' element={<SubscriptionSuccess/>} />
          <Route path='subscription-failure' element={<SubscriptionFailure/>} />

          <Route path='subscription-history' element={<SubscriptionHistory/>}/>

        </Route>
          
        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="/" element={<Landing />} /> */}
      </Routes>
    </div>
  );
}

export default App;

const CreateInvoiceWithLimitCheck = ({type,RenderComponent}) => {
  const [redirectedDueToLimit, setRedirectedDueToLimit] = useState(false);
  const planDetail = useSelector(state => state.planDetails?.planDetails?.plan_usage_details);

  // console.log("type::",type)
  useEffect(() => {
    // Check if the usage count exceeds the total count
    if (planDetail) {
      if (planDetail[type]?.usage_count >= planDetail[type]?.total) {
        setRedirectedDueToLimit(true);
      }
      
    }
  }, [planDetail]); // Empty dependency array ensures this runs only once on component mount

  return redirectedDueToLimit ? (
    <Navigate to="/subscription-plans" replace />
  ) : (
    RenderComponent && <RenderComponent/> 
  );
};
